import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signOut } from 'https://www.gstatic.com/firebasejs/9.1.2/firebase-auth.js'
//import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const auth = getAuth();

$(document).ready(function() {


$( "#btnRegister" ).click(function() {
  window.location.replace("register.html");
});

$( "#btnRecoverPassword" ).click(function() {

  let email = $("#emailInput").val().trim();

  if (email == "") {
    alert("Please provide your email in the field above.")
    return;
  }

  $.post("/verify-captcha",`token=${grecaptcha.getResponse()}`)
  .done(result => {

      if (result.status == "success") {


            sendPasswordResetEmail(auth, email)
              .then(() => {
                // Password reset email sent!
                // ..
                alert("Password reset email sent!");
              })
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                switch (errorCode) {
                  case "auth/user-not-found":
                    alert("Your user was not found. Did you register?");
                    break;
     
                  case "auth/user-disabled": 
                  case "auth/user-cancelled": 
                    alert("Your user is inactive. Please contact Support.");
                    break;
            
                  case "auth/wrong-password": 
                  case "auth/invalid-email":
                    alert("Invalid email.");
                    break;
            
                  default:
                    alert(errorMessage);
                }
              });

      } else {
        alert("reCaptcha challenge failed. Please contact support if the problem persists.");                       
      }

  })
  .fail(result => {
    alert("reCaptcha challenge failed. Please contact support if the problem persists.");                    
  });
   
});

$( "#btnLogin" ).click(function() {

  //event.preventDefault();

  let email = $("#emailInput").val().trim();
  let password = $("#pwdInput").val().trim();

  if (email == "" || password == "") {
    alert("Please provide your credentials.")
    return;
  }

    $.post("/verify-captcha",`token=${grecaptcha.getResponse()}`)
    .done(result => {

        if (result.status == "success") {

          signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
        
            if (user.emailVerified)
              window.location.replace("index.html");
            else {
        
                sendEmailVerification(user)
                .then(() => {
                  // Email verification sent!
                  alert("Please verify your email address. A verification email has been re-sent.");
                });
        
            } 
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        
            switch (errorCode) {
              case "auth/user-not-found":
                alert("Your user was not found. Did you register?");
                break;
 
              case "auth/user-disabled": 
              case "auth/user-cancelled": 
                alert("Your user is inactive. Please contact Support.");
                break;
        
              case "auth/wrong-password": 
              case "auth/invalid-email":
                alert("Wrong username or password.");
                break;
        
              default:
                alert(errorMessage);
            }
        
            
          });

        } else {
           alert("reCaptcha challenge failed. Please contact support if the problem persists.");                       
        }

    })
    .fail(result => {
      alert("reCaptcha challenge failed. Please contact support if the problem persists.");                    
    });

  
});

$( "#btnCreate" ).click(function() {
  let name = $("#inputName").val().trim();
  let email = $("#inputEmail").val().trim();
  let password = $("#inputPassword").val().trim();
  let passwordRepeat = $("#inputRepeatPassword").val().trim();

  if (email == "" || password == "" || name == "" || passwordRepeat == "") {
    alert("Please provide your information.")
    return;
  }

  if (password != passwordRepeat) {
    alert("The passwords do not match.")
    return;
  }

  $.post("/verify-captcha",`token=${grecaptcha.getResponse()}`)
  .done(result => {

      if (result.status == "success") {

          createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;

            updateProfile(user, {
              displayName: name, 
            }).then(() => {
                // Profile updated!
                console.log("profile updated");
            }).catch((error) => {
                // An error occurred
                // ...
                console.log(error);
            });

            sendEmailVerification(user)
            .then(() => {
              // Email verification sent!
              alert("Please verify your email address. A verification email has been sent.");
              window.location.replace("index.html");
            });
            
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            switch (errorCode) {
              case "auth/email-already-in-use": 
                alert("Address in use. Please provide another email.");
                break;

              case "auth/invalid-email":
                alert("Please provide a valid email.");
                break;

              default:
                alert(errorMessage);
            }

            
          });

        } else {
          alert("reCaptcha challenge failed. Please contact support if the problem persists.");                       
       }

   })
   .fail(result => {
     alert("reCaptcha challenge failed. Please contact support if the problem persists.");                    
   });


});


  $( "#linkLogout" ).click(function() {
      logout();
  });

  $( "#linkLogout2" ).click(function() {
    logout();
});

});

function logout() {
  console.log("linklogout");

  auth.signOut()
  .then(() => {
      window.location.replace("login.html");
  })
  .catch((error) => {
    alert("An error ocurred, please try again and if the problem persists, report it to support.");
    
  });
}

  